














































import { apiGETMulti, apiGETSingle, axiosConfig } from '@/api';
import HostLeaderboard from '@/components/Dashboard/CasterLeaderboard.vue';
import TwitchPlayerPanel from '@/components/Dashboard/TwitchPlayerPanel.vue';
import EventSelect from '@/components/EventSelect.vue';
import { rootModule } from '@/store';
import { API } from '@/types';
import axios from 'axios';
import clone from 'clone';
import { orderBy } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Socket } from 'vue-socket.io-extended';
import { storeModule, storeNS } from '../store';
import ChangeViewPlayerBtn from './components/ChangeViewPlayerBtn.vue';
import CommercialDensity from './components/CommercialDensity.vue';
import CurrentPlaybackStatus from './components/CurrentPlaybackStatus.vue';
import ViewControl from './components/ViewControl.vue';

@Component({
  components: {
    TwitchPlayerPanel,
    HostLeaderboard,
    CommercialDensity,
    CurrentPlaybackStatus,
    ChangeViewPlayerBtn,
    ViewControl,
    EventSelect,
  },
})
export default class extends Vue {
  @storeNS.State players!: API.Players.GET[];
  playerOrder: API.PlayerOrder.GET | null = null;
  videoPlaylists: API.Videos.Playlists.GET[] = [];
  videoPlayerData: API.Videos.VPlayerData.GET | null = null;
  disableMVLeaderboardBtn = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    // Reload relevant API data if event happens to change, or on load.
    this.loadPlayerOrderAPIData();
    this.loadVideoPlaylistsAPIData();
    this.loadVPlayerDataAPIData();
  }

  async loadPlayerOrderAPIData(): Promise<void> {
    if (this.selectedEvent) {
      try {
        const { data } = (await apiGETSingle('playerOrder', this.selectedEvent));
        Vue.set(this, 'playerOrder', data);
      } catch (err) {
        Vue.set(this, 'playerOrder', null);
      }
    }
  }

  @Socket('playerOrderModified')
  scktOrderModified(val: API.PlayerOrder.GET | null): void {
    if (this.selectedEvent === val?.eventId) {
      Vue.set(this, 'playerOrder', { ...this.playerOrder, ...val });
    }
  }

  async loadVideoPlaylistsAPIData(): Promise<void> {
    if (this.selectedEvent) {
      const { data } = (await apiGETMulti('videoPlaylists', {
        eventId: this.selectedEvent,
      }, true));
      Vue.set(this, 'videoPlaylists', data);
    }
  }

  @Socket('videoPlaylistModified')
  scktPlaylistModified(
    newVal: API.Videos.Playlists.GET | null,
    oldVal: API.Videos.Playlists.GET | null,
  ): void {
    const index = this.videoPlaylists.findIndex((p) => p.id === oldVal?.id);
    if (index >= 0) {
      if (newVal) Vue.set(this.videoPlaylists, index, { ...this.videoPlaylists[index], ...newVal });
      else this.videoPlaylists.splice(index, 1);
    } else if (newVal && newVal.eventId === rootModule.selectedEvent) {
      this.videoPlaylists.unshift(newVal);
    }
  }

  async loadVPlayerDataAPIData(): Promise<void> {
    if (this.selectedEvent) {
      try {
        const { data } = (await apiGETSingle('videoPlayerData', this.selectedEvent));
        Vue.set(this, 'videoPlayerData', data);
      } catch (err) {
        Vue.set(this, 'videoPlayerData', null);
      }
    }
  }

  @Socket('videoPlayerDataModified')
  scktVPlayerDataModified(val: API.Videos.VPlayerData.GET | null): void {
    if (this.selectedEvent === val?.eventId) {
      Vue.set(this, 'videoPlayerData', { ...this.videoPlayerData, ...val });
    }
  }

  playerModified(player: API.Players.GET): void {
    storeModule.updateStoredPlayer(player);
  }

  playerOrderModified(val: API.PlayerOrder.GET): void {
    Vue.set(this, 'playerOrder', { ...this.playerOrder, ...val });
  }

  get eventPlaylists(): API.Videos.Playlists.GET[] {
    return orderBy(this.videoPlaylists, ['name'], ['asc']);
  }

  get playersAlphabetical(): API.Players.GET[] {
    return this.players.filter((p) => p.eventId === this.selectedEvent)
      .sort((a, b) => {
        const aName = a.user?.name || '?';
        const bName = b.user?.name || '?';
        return aName.localeCompare(bName, 'en', { sensitivity: 'base' });
      });
  }

  // Get current event player order, makes sure they're ordered, then maps player info.
  get playersSorted(): (API.Players.GET | undefined)[] {
    return clone(this.playerOrder?.value || [])
      .map((o) => this.players.find((p) => p.id === o))
      .filter((p) => !!p);
  }

  async showLeaderboard(): Promise<void> {
    if (this.selectedEvent) {
      await axios.post(`/nodecg/show_leaderboard/${this.selectedEvent}`, null, axiosConfig());
      this.disableMVLeaderboardBtn = true;
      window.setTimeout(() => { this.disableMVLeaderboardBtn = false; }, 40 * 1000);
    }
  }

  async showVideoPlayer(playlistId: number): Promise<void> {
    if (this.selectedEvent) {
      await axios
        .post(`/nodecg/show_videoplayer/${this.selectedEvent}`, { playlistId }, axiosConfig());
    }
  }

  get playerData(): API.Videos.VPlayerData.GET['value'] {
    return clone(this.videoPlayerData?.value || { playing: false, video: null });
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }
}
